import React, { useState, useEffect, useRef, useCallback } from "react";
import DateRangeIcon from "@material-ui/icons/DateRange";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
// import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
// import VpnKeyIcon from "@material-ui/icons/VpnKey";
import axios from "axios";
// import GoogleMapReact from "google-map-react";
import ReactFlagsSelect from "react-flags-select";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-number-input";
import { FormGroup, Input } from "reactstrap";
import "../../utils/firebase";
// import admin from  '../../utils/firebaseAdminUtils';
import { getDatabase, ref, set, push } from "firebase/database";
import {
  getAuth,
  createUserWithEmailAndPassword,
  getIdToken,
} from "firebase/auth";
//import StripeCheckout from "react-stripe-checkout";
import dayjs from "dayjs";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import logo from "../../assets/out2.png";
import slideImg from "../../assets/img_step1.png";
import classicImg from "../../assets/classic.png";
import talentsImg from "../../assets/talent.png";
import "../Animation.css";
import "./Razor.css";
import "./FormTest.scss"; //Remove Test
import "react-datepicker/dist/react-datepicker.css";
import Popups from "../Checkout/Popups";
import Stripes from "../Checkout/Stripes";
import Paypal from "../Checkout/Paypal";
// import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "../Checkout/PopupJS.css";
import "react-phone-number-input/style.css";

import { useSearchParams } from "react-router-dom";
// import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { Stripe } from "stripe";
import useRazorpay from "react-razorpay";
import e from "cors";

const stripePromise = loadStripe("pk_live_HAc3M5WIGeAtNCh5agGLRy77");
//PaneExample is assigned a value but never used
const PaneExample = ({ children, style, className }) => (
  <div className={`glider-slide ${className}`} style={style}>
    <h1>{children}</h1>
  </div>
);

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};


let shouldStop = false;
function Forms(props) {
  const { t } = useTranslation();

  const isFirstRun = useRef(true);

  //////////////////////////////
  ////    Hooks useState    ///
  ////////////////////////////

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const [isValidVoucher, setIsValidVoucher] = useState(false);
  const [voucher, setVoucher] = useState("");
  const [isItFinished, setIsItFinished] = useState(false);

  //state phone in condition india
  const [phone, setPhone] = useState("");
  const [otpCode, setOtpCode] = useState("");
  const [sentOtp, setSentOtp] = useState(false);

  ////     Form 2      ////
  const [email, setEmail] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  ////     Form 3      ////
  // GoogleMaps
  const [region, setRegion] = useState("");
  const [regionCode, setRegionCode] = useState('');
  const [errorPosition, setErrorPosition] = useState(false);
  const [errorRegion, setErrorRegion] = useState(false); //errorrRegion is assigned and never used
  const [addresse, setAddresse] = useState("");
  const [errorAddresse, setErrorAddresse] = useState(false);

  const [gender, setGender] = useState("");

  const [dateNaissance, setDateNaissance] = useState();
  const [errorAge, setErrorAge] = useState(false);

  //// Final Forms Send
  const [finalResult, setFinalResult] = useState(false);
  const [cguBtn, setCguBtn] = useState(false);
  const [godfatherBtn, setGodfatherBtn] = useState(false);

  //// Phone verification
  const [errorPhone, setErrorPhone] = useState(false);
  // razorpay state
  const [subInfo, setSubInfo] = useState({ plan: 0 });
  // verifyEmail 
  const [emailError, setEmailError] = useState(false)

  //const [isDataFromSocial, setDataFromSocial] = useState(false); // !!!! Set to false for prod

  /////////////////////////////////////////////////////////////////////////
  ////////////////        Social Data Verification        ////////////////
  ///////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////
  ////////    Fetching Data     ////////
  /////////////////////////////////////

  ///////////////////////////////////////////////////
  // Retrieve Data From React-Native throught URL //
  /////////////////////////////////////////////////

  const [searchParams] = useSearchParams();
  let uid = useRef(false);
  let firstName = useRef("");
  let lastName = useRef("");

  useEffect(() => {
    if (searchParams.get("uid") !== null) {
      // setDataFromSocial(true);
      // Or
      // DetectDataFromSocial();                                           
      uid.current = true;
      //isDataFromTheSocial.current = true;
      if (searchParams.get("firstName") !== null) {
        firstName.current = searchParams.get("firstName");
      }
      if (searchParams.get("lastName") !== null) {
        lastName.current = searchParams.get("lastName");
      }
      if (searchParams.get("mail") !== null) {
        lastName.current = searchParams.get("mail");
      }
    }
  }, [searchParams]);

  //////////////////////////////////////////////////////
  ////////        Hook For BirthDay SetUp      ////////
  ////////////////////////////////////////////////////

  useEffect(() => {
    setDateNaissance(dayjs("2000-01-01").format("YYYY-MM-DD"));
    // axios.post('http://localhost:5000/mymotheragency-208817/us-central1/token/generate')
    // .then(res => {
    //     setToken(res.data)
    // })

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  var birthDay = document.getElementById("birth");
  if (birthDay) {
    birthDay.addEventListener("change", function (e) {
      if (Date.parse(birthDay.value)) {
        setDateNaissance(birthDay.value);
        var year = birthDay.value.substr(0, 4);
        var current_year = new Date().getFullYear();
        var userAn = current_year - year;
        if (NorthAmerica.includes(formData.region) && userAn < 18) {
          setErrorAge(true);
          e.preventDefault();
        } else if (userAn < 18) {
          setErrorAge(true);
          e.preventDefault();
        }
      }
    });
  }

  //////////////////////////////////////
  ////////     TaskBar CSS     ////////
  ////////////////////////////////////

  let [step, setStep] = useState(1);
  let opacity = {
    opacity: 0.01,
    pointerEvents: "none",
  };
  let style1 = {
    margin: "0px",
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
    backgroundColor: "#e62375",
  };
  let style2 = {
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
    backgroundColor: "#e62375",
    animation: "progres 2s ease",
  };
  let style3 = {
    margin: "0px",
    borderRadius: "0px",
    backgroundColor: "#e62375",
    animation: "progres2 2s ease",
  };

  //////////////////////////////////////
  ////////     Media Query     ////////
  ////////////////////////////////////

  // Create a condition that targets viewports at least 768px tall
  const mediaQuery = window.matchMedia("(max-height: 768px)");
  function handleMediaQueryChange(e) {
    // Check if the media query is true
    if (e.matches) {
      var formin = document.getElementById("form_tow");
      var formin2 = document.getElementById("form_three");
      var formin3 = document.getElementById("form_four");
      var formin4 = document.getElementById("form_five");
      var formin5 = document.getElementById("formThree");

      switch (step) {
        case 1:
          // !! Warning !! -> error when going to 769px to 768px to 769px => Second form not reappearing or generating an error
          // Trying to fix when formin is null with if statement
          if (formin) {
            if (formin !== null) {
              formin.classList.add("hide");
            }
          }
          break;
        case 2:
          if (formin !== null) {
            formin.classList.remove("hide");
            //formin2.classList.add("hide");
          }
          break;
        case 3:
          if (formin5 !== null) {
            formin5.classList.remove("hide");
            //formin4.classList.add("hide");
          }
          break;
        case 4:
          if (formin2 !== null) {
            formin2.classList.remove("hide");
          }
          //formin3.classList.add("hide");
          break;
        case 5:
          if (formin3 !== null) {
            formin3.classList.remove("hide");
            //formin4.classList.add("hide
          }
          break;
        case 6:
          if (formin4 !== null) {
            formin4.classList.remove("hide");
          }
          //formin5.classList.add("hide");
          break;

        default:
          break;
      }
    }
  }

  // Register event listener
  mediaQuery.addListener(handleMediaQueryChange);

  // Initial check
  handleMediaQueryChange(mediaQuery);

  ///////////////////////////////////////////////////////////////////////////////////////////
  ////////        Function For Form increment animation, opacity and buttonClick    ////////
  /////////////////////////////////////////////////////////////////////////////////////////

  const arrowFunction = () => {
    var formin = document.getElementById("form_tow");
    var formin2 = document.getElementById("form_three");
    var formin3 = document.getElementById("form_four");
    var formin4 = document.getElementById("form_five");
    var formin5 = document.getElementById("form_six");
    var formin6 = document.getElementById("formThree");

    switch (step) {
      // case (1 && searchParams.get('uid') !== null):
      case 1:
        document.getElementById("form1").click();
        // var formin = document.getElementById("form_tow");
        formin.classList.add("w3-animate-bottom");
        formin2.classList.add("overflowFlexHidden");
        break;
      case 2:
        document.getElementById("form2").click();
        // var formin2 = document.getElementById("form_three");
        formin2.classList.add("w3-animate-bottom");

        if (enSouthAsia.includes(formData.region)) {
          formin6.classList.add("overflowFlexHidden");
        } else {
          formin3.classList.add("overflowFlexHidden");
        }
        break;
      case 3:
        if (!enSouthAsia.includes(formData.region)) {
          document.getElementById("form3").click();
          formin3.classList.add("w3-animate-bottom");

          //formin4.classList.add("overflowFlexHidden");
        } else {
          document.getElementById("form6").click();
          formin6.classList.add("w3-animate-bottom");

          formin3.classList.add("overflowFlexHidden");
        }
        break;
      case 4:
        document.getElementById("form3").click();
        // var formin3 = document.getElementById("form_four");
        formin3.classList.add("w3-animate-bottom");

        // formin3.classList.remove("overflowFlexHidden");
        formin4.classList.add("overflowFlexHidden");
        break;
      case 5:
        document.getElementById("form3").click();
        formin4.classList.add("w3-animate-bottom");
        break;
      case 6:
        document.getElementById("form5").click();
        formin5.classList.add("w3-animate-bottom");
        break;

      default:
        break;
    }
  };

  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////                What Do Forms                   ////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////

  //  Function Step Form Increment
  const changeStep = () => {

    setTimeout(() => {
      shouldStop = false;
    }, 5000);

    if (shouldStop) { return; }
    shouldStop = true;
    setStep((value) => value + 1);
  };

  //  Function to set Form to "3" if uid === True && firstName && lastName
  const NumStepTo3 = () => {
    useEffect(() => {
      if (uid.current && firstName.current && lastName.current) {
        setStep((value) => 3);
        document.getElementById("numStep").innerText = 3;

        // ERROR HERE => Fix OVERFLOWFLEXHIDDEN
        var formin3 = document.getElementById("form_four");
        formin3.classList.add("overflowFlexHidden");
      }
    }, [uid, firstName, lastName]);
  };

  //  Function To Remove Arrow On Form 5
  const changeArrow = () => {
    if (
      (step < 6 && enSouthAsia.includes(formData.region)) ||
      (step < 4 && !enSouthAsia.includes(formData.region))
    ) {
      document.getElementById("arrow").innerHTML = "&#8595;";

      return;
    }
    document.getElementById("arrow-suitch").style.display = "none";
  };

  //////////////////////////////////////////////////////////////////////
  ////////                Data Form Implementation             ////////
  ////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////
  ////////   Forms.Errors.Messages&Data    ////////
  ////////////////////////////////////////////////

  const {
    register: register1,
    formState: { errors: errors1 },
    handleSubmit: handleSubmit1,
  } = useForm();
  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm();
  const {
    register: register3,
    formState: { errors: errors3 },
    handleSubmit: handleSubmit3,
  } = useForm();
  const {
    register: register4,
    formState: { errors: errors4 },
    handleSubmit: handleSubmit4,
  } = useForm();
  //This is form Twilio
  const {
    register: register6,
    formState: { errors: errors6 },
    handleSubmit: handleSubmit6,
  } = useForm();

  ////    Form Data
  const [formData, setFormData] = useState({});
  const [subscriptions, setSubscriptions] = useState({});
  const [mMP, setMMP] = useState("");
  const [talentLabBool, setTalentLabBool] = useState(false);


  let onSubmit1 = null;
  let onSubmit2 = null;
  let onSubmit3 = null;
  let onSubmit4 = null;
  let onSubmit6 = null;

  ////    Check If Social OK and if firstName and lastName to submit forms (Step 1 or Step 3 ?)

  if (uid.current && firstName.current === "" && lastName.current === "") {
    // Data retrieve from Social Login
    let userUid = searchParams.get("uid");

    onSubmit1 = (data) => {
      data.region = region;

      if (region == '') { setErrorAddresse(true); return; }
      setFormData((result) => {
        return { ...result, ...data, uid: userUid };
      });

      changeStep();
      changeArrow();
      handleMMpartner();
      document.getElementById("numStep").innerText = step + 1;
    };
    onSubmit2 = (data) => {
      setFormData((result) => {
        return { ...result, ...data };
      });
      verifyEmail().then(res => {
        // if (res.data.emailExist) {

        if (res.data.emailExist == false) {
          setEmailError(false);
          changeStep();
          handleMMpartner();
          changeArrow();
          document.getElementById("numStep").innerText = step + 1;

        }
        else if (res.data.emailExist == true) {
          setEmailError(true)

        }
        // }
      })
      // .catch(err => {
      //   setEmailError(true)
      // })
    };
    onSubmit3 = (data) => {
      if (addresse === "") {
        setErrorAddresse(true);
        return;
      }
      const _age = dayjs().diff(dayjs(dateNaissance), "year");
      if (_age < 18) {
        setErrorAge(true);
        return;
      }
      setErrorAge(false);

      document.getElementById("iconClick").click();
      if (errorPosition) {
        return;
      }
      if (region === "") {
        setErrorRegion(true);
        return;
      }

      setFormData((result) => {
        return {
          ...result,
          ...data,
          // region,
          dateNaissance: dayjs(dateNaissance).unix(),
          gender: gender,
        };
      });
      changeStep();
      changeArrow();
      document.getElementById("numStep").innerText = step + 1;
      // var formin3 = document.getElementById("form_four");
      // formin3.classList.remove("overflowFlexHidden");
    };
    onSubmit4 = (data) => {
      data.Godfather = godfatherBtn;
      data.cgu = cguBtn;
      if (!cguBtn) {
        return;
      }
      setFormData((result) => {
        return { ...result, ...data, dataTest };
      });
      // changeStep();
      // changeArrow();
      // document.getElementById("numStep").innerText = step + 1;
      setFinalResult(true);
    };
    onSubmit6 = (data) => {
      if (verifyTwilioCode()) {
        changeStep();
        changeArrow();
        document.getElementById("numStep").innerText = step + 1;
      }
    };
  } else if (
    searchParams.get("uid") &&
    searchParams.get("firstName") &&
    searchParams.get("lastName")
  ) {
    // Data retrieve from Social Login
    let userUid = searchParams.get("uid");
    let emailFromUrl = searchParams.get("mail");

    //SetFormDataWithSocial();
    NumStepTo3();

    onSubmit3 = (data) => {
      if (addresse === "") {
        setErrorAddresse(true);
        return;
      }
      const _age = dayjs().diff(dayjs(dateNaissance), "year");
      if (_age < 18) {
        setErrorAge(true);
        return;
      }
      setErrorAge(false);

      document.getElementById("iconClick").click();
      if (errorPosition) {
        return;
      }
      if (region === "") {
        setErrorRegion(true);
        return;
      }

      setFormData((result) => {
        return {
          ...result,
          ...data,
          // region,
          dateNaissance: dayjs(dateNaissance).unix(),
          gender: gender,
          uid: userUid,
          email: emailFromUrl,
        };
      });
      changeStep();
      changeArrow();
      document.getElementById("numStep").innerText = step + 1;
      // var formin3 = document.getElementById("form_four");
      // formin3.classList.remove("overflowFlexHidden");
    };
    onSubmit4 = (data) => {
      data.Godfather = godfatherBtn;
      data.cgu = cguBtn;
      if (!cguBtn) {
        return;
      }
      setFormData((result) => {
        return { ...result, ...data, dataTest };
      });
      // changeStep();
      // changeArrow();
      // document.getElementById("numStep").innerText = step + 1;
      setFinalResult(true);
    };
    onSubmit6 = (data) => {
      if (verifyTwilioCode()) {
        changeStep();
        changeArrow();
        document.getElementById("numStep").innerText = step + 1;
      }
    };
  } else {
    onSubmit1 = (data) => {
      data.region = region;
      console.log(region);
      if (region == '') { setErrorAddresse(true); return; }
      setFormData((result) => {
        return { ...result, ...data, region };
      });
      changeStep();
      changeArrow();
      handleMMpartner();
      document.getElementById("numStep").innerText = step + 1;
    };
    onSubmit2 = (data) => {
      setFormData((result) => {
        return { ...result, ...data };
      });
      verifyEmail().then(res => {
        // if (res.data.emailExist) {

        if (res.data.emailExist == false) {
          setEmailError(false);
          changeStep();
          handleMMpartner();
          changeArrow();
          document.getElementById("numStep").innerText = step + 1;

        }
        else if (res.data.emailExist == true) {
          setEmailError(true)

        }
        // }
      })
      // .catch(err => {
      //   setEmailError(true)
      // })
    };
    onSubmit3 = (data) => {
      if (addresse === "") {
        setErrorAddresse(true);
        return;
      }
      const _age = dayjs().diff(dayjs(dateNaissance), "year");
      if (_age < 18) {
        setErrorAge(true);
        return;
      }
      setErrorAge(false);

      document.getElementById("iconClick").click();
      if (errorPosition) {
        return;
      }
      if (region === "") {
        setErrorRegion(true);
        return;
      }

      setFormData((result) => {
        return {
          ...result,
          ...data,
          dateNaissance: dayjs(dateNaissance).unix(),
          gender: gender,
        };
      });
      changeStep();
      changeArrow();
      document.getElementById("numStep").innerText = step + 1;
    };
    onSubmit4 = (data) => {
      data.Godfather = godfatherBtn ? true : false;
      data.cgu = cguBtn ? true : false;
      if (!cguBtn) {
        return;
      }
      setFormData((result) => {
        return { ...result, ...data, dataTest };
      });
      // changeStep();
      // changeArrow();
      // document.getElementById("numStep").innerText = step + 1;
      setFinalResult(true);
    };
    onSubmit6 = (data) => {
      setFormData((result) => {
        return { ...result, ...data, phone };
      });
      verifyTwilioCode()
        .then((res) => {
          if (res.data.status === "approved") {
            setErrorPhone(false);
            changeStep();
            changeArrow();
            document.getElementById("numStep").innerText = step + 1;
          } else setErrorPhone(true);
        })
        .catch((err) => {
          setErrorPhone(true);
        });
    };
  }

  //////////////////////////////////////////////////////////////////////
  ////////        Implementation de la Data GoToForm -> 3      ////////
  ////////////////////////////////////////////////////////////////////

  ////////    !!!!!!!!     PlaceHolder Social DATA     !!!!!!!!    ////////
  const propsData = {
    idTokenFb: "FbToken00",
    idTokenGoogle: "GoogleToken01",
    idTokenApple: "AppleToken02",
    firstName: "Jose",
    lastName: "Bové",
    email: "JoseBove@gmail.com",
  };

  let dataTest = {
    users: {
      cgu: true,
      godfather: {
        amount: 10,
        tt: "",
      },
      godfatherAmount: 5,
      group: "default",
      hasCompletedSubscribe: true,
      isRefunded: false,
      trialPeriod: 1,
      paymentValidation: false,
      talentLab: talentLabBool,
    },
    data: {
      models: {
        address: {
          description: "",
          matched_substrings: [
            {
              length: null,
              offset: null,
            },
          ],
          place_id: "",
          reference: "",
          structured_formatting: {
            main_text: "",
            main_text_matched_substrings: [
              {
                length: null,
                offset: null,
              },
            ],
            secondary_text: "",
          },
          terms: [
            {
              offset: null,
              value: "",
            },
            {
              offset: null,
              value: "",
            },
            {
              offset: null,
              value: "",
            },
            {
              offset: null,
              value: "",
            },
          ],
          types: ["", ""],
        },
        addressPackage: {
          city: "",
          country: "",
          countryCode: "",
          lat: null,
          lng: null,
        },
        atypicalNatures: ["originalHair", "freckles"],
        birthday: "",
        details: {
          bottomConfection: null,
          chest: null,
          crotch: null,
          ethnicity: "",
          eyeColor: "",
          hairColor: "",
          hairLength: "",
          hairType: "",
          height: null,
          hips: null,
          shoesSlide: null,
          topConfection: null,
          waist: null,
        },
        email: "",
        firstName: "",
        gender: "",
        phone: phone,
        godfatherUid: "",
        hasCompletedProfile: true,
        lastName: "",
        looks: [""],
        mobile: phone ,
        musicInstruments: [""],
        nationalities: [
          {
            key: "",
            label: "",
          },
        ],
        profile: {
          //   200 : {
          //     date : null,
          //     disabled : false,
          //     fileName : "",
          //     url : ""
          //   },
          //   300 : {
          //     date : null,
          //     disabled : false,
          //     fileName : "",
          //     url : ""
          //   },
          //   400 : {
          //     date : 1646044459,
          //     disabled : false,
          //     fileName : "Story quest4 (1)-1.png",
          //     url : "https://lh3.googleusercontent.com/jRtC9KRvAUgbu4OHt6dLhXjYIb1WHZs_nWkqZeU45JXS10nVyjt1EVYakLLjdJ5TweWkKLaApZmbvUWDgLu_YZTHwrOMz2NGhcG8qtD6qXtZHeF7HXA=w400-h400-c-pp-l100-rj"
          //   },
          //   "1000" : {
          //     "date" : 1646044459,
          //     "disabled" : false,
          //     "fileName" : "Story quest4 (1)-1.png",
          //     "url" : "https://lh3.googleusercontent.com/jRtC9KRvAUgbu4OHt6dLhXjYIb1WHZs_nWkqZeU45JXS10nVyjt1EVYakLLjdJ5TweWkKLaApZmbvUWDgLu_YZTHwrOMz2NGhcG8qtD6qXtZHeF7HXA=w1000-h1000-c-pp-l100-rj"
          //   },
          //   "1500" : {
          //     "date" : 1646044459,
          //     "disabled" : false,
          //     "fileName" : "Story quest4 (1)-1.png",
          //     "url" : "https://lh3.googleusercontent.com/jRtC9KRvAUgbu4OHt6dLhXjYIb1WHZs_nWkqZeU45JXS10nVyjt1EVYakLLjdJ5TweWkKLaApZmbvUWDgLu_YZTHwrOMz2NGhcG8qtD6qXtZHeF7HXA=w1500-h1500-c-pp-l100-rj"
          //   },
          //   "original" : {
          //     "date" : 1646044459,
          //     "disabled" : false,
          //     "fileName" : "Story quest4 (1)-1.png",
          //     "url" : "https://storage.googleapis.com/mymotheragency-208817.appspot.com/images%2FZQmATwZy6PeMADhnGYqZ4oNTqwm2%2Fprofile%2Fbw3_ef018566-ccfd-495d-9443-2123cbfdfd60_Story%20quest4%20(1)-1.png"
          //   }
        },
        qualifications: ["model"],
        representedByAnAgency: false,
        spokenLanguage: [
          {
            key: "",
            label: "",
          },
        ],
        sports: [""],
        submittedProperties: {
          address: true,
          atypicalNatures: true,
          bottomConfection: true,
          chest: true,
          crotch: true,
          ethnicity: true,
          experiences: false,
          eyeColor: true,
          hairColor: true,
          hairLength: true,
          hairType: true,
          height: true,
          hips: true,
          jobZones: false,
          jobZonesCountries: false,
          looks: true,
          musicInstruments: true,
          portfolioLink: false,
          pseudo: false,
          qualifications: true,
          shoesSlide: true,
          spokenLanguage: true,
          sports: true,
          topConfection: true,
          transport: true,
          waist: true,
          worldWide: true,
        },
        transport: {
          transportMoto: true,
        },
        worldWide: true,
        MMPartner: mMP,
      },
    },
  };


  // // Reception de la data de React Native
  // let { dataProps } = useParams();
  // Soit
  // Via injectedJavaScript -> https://dev.to/mukeshmandiwal/webview-data-flow-with-webviews-in-a-react-native-app-and-navigation-4396
  // Let's implement
  const handleEmail = (e) => {

    setEmail(e.target.value);
  };

  const handleChange = (address) => {
    setAddresse(address);
    // setRegion(address);
    // setErrorRegion(false);
    setErrorAddresse(false);
  };

  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setAddresse(address);
        setErrorAddresse(false);
      })
      .catch((error) => console.error("Error", error));
  };
  function Mailto({ email, subject, body, ...props }) {
    return (
      <a style={{ textDecoration: 'none', color: 'red', cursor: 'pointer' }} href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
        {props.children}
      </a>
    );
  }
  const form1And2 = (
    <>
      <div className="formOne forms w3-animate-bottom" hidden={step > 1}>
        <div id="formOneName">
          <span className="stepNumber">{step.toString().padStart(2, "0")}</span>
          <h2>{t("Register.welcomeMsg")}</h2>
          <h2>{t("Register.firstStep")}</h2>
        </div>
        <form onSubmit={handleSubmit1(onSubmit1)} autoComplete="off">
          <FormGroup row>
            <div className="inputContainer" id="iconClick" >
              {/* <Input
                                            id="country" placeholder=t("Register.locationClick")
                                            style={
                                                region !== "" ? {borderRadius: "14px 14px 0 0"} : null
                                            }
                                            {...register3("position", {
                                                required: "Position is required",
                                            })}
                                        /> */}

              {/* <ReactFlagsSelect
                showSelectedLabel={true}
                showSecondarySelectedLabel={true}
                selectedSize={16}
                rfsKey="app-lang"
                showOptionLabel={true}
                showSecondaryOptionLabel={true}
                optionsSize={14}
                placeholder={"Select country"}
                // searchable={true}
                searchPlaceholder={"Search..."}
                alignOptionsToRight={false}
                fullWidth={true}
                disabled={false}
                onSelect={e => {
                  setRegionCode(e);
                  handleChange(countries[e]);
                }}
                selected={regionCode}
              /> */}
              {/*  this is the old input of country*/}

              <PlacesAutocomplete
                value={addresse}
                onChange={handleChange}
                onSelect={handleSelect}
                searchOptions={{ types: ["country"] }}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <input
                      {...getInputProps({
                        id: "country",
                        placeholder: "",
                        className: "location-search-input",
                        // register:register3('position', {
                        //     required: 'Position is required'
                        // })
                      })}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Chargement...</div>}
                      {suggestions.map((suggestion, index) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? {
                            backgroundColor: "#34393e",
                            cursor: "pointer",
                          }
                          : {
                            backgroundColor: "#34393e",
                            cursor: "pointer",
                          };
                        return (
                          <div
                            key={index}
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>

              <div onClick={() => findPays()} id="iconClick">
                {" "}
                <MyLocationIcon
                  className="icon"
                  style={{ cursor: "pointer" }}
                />
              </div>

              <div className="label">{t("Register.location")}</div>
              <div
                style={region === "" ? { display: "none" } : null}
                className="region"
              >
                <div>{region}</div>
                <HighlightOffIcon
                  onClick={() => setRegion("")}
                  style={{ cursor: " pointer" }}
                />{" "}
              </div>

              {/*
                                                <div
                                                    style={{
                                                        height: "200px",
                                                        width: "80%",
                                                        margin: "auto",
                                                        marginTop: "5px",
                                                    }}
                                                >
                                                    <GoogleMapReact
                                                        hidden={true}
                                                        onClick={_onClick}
                                                        center={center}
                                                        defaultZoom={10}
                                                        bootstrapURLKeys={{
                                                            key: "AIzaSyB6znF7jDzYiQeSCHGMIF0rLSL6qktnlrE",
                                                        }}
                                                    ></GoogleMapReact>
                                                </div>

                                            */}

              {/* <div className="error">
                {errorPosition && t("Register.locationError")}
              </div>
              <div className="error">
                {errorRegion && t("Register.locationInvalid")}
              </div> */}
              <div className="error">
                {errorAddresse && t("Register.locationHint")}
              </div>
            </div>
            <div className="inputContainer">
              <input
                name="firstName"
                type="text"
                {...register1("firstName", { required: true })}
              />
              <div className="label-champ">{t("Register.lastNameHint")}</div>
              <div className="label">{t("Register.lastName")}</div>
            </div>
            <div className="error">
              {errors1.firstName && t("Register.lastNameError")}
            </div>
            <div className="inputContainer">
              <input
                name="lastName"
                type="text"
                {...register1("lastName", { required: true })}
              />
              <div className="label-champ">{t("Register.nameHint")}</div>
              <PersonOutlineIcon className="icon" />
              <div className="label">{t("Register.name")}</div>
            </div>
            <div className="error">
              {errors1.lastName && t("Register.nameError")}
            </div>
           
          </FormGroup>
          <button type="submit" className="hideButton" id="form1"></button>
        </form>
      </div>

      <div
        id="form_tow"
        className="formTwo forms"
        hidden={step > 2}
        style={step === 1 ? opacity : null}
      >
        <div id="formTwoName">
          <span className="stepNumber">02</span>
          <h2>{t("Register.secondStep")}</h2>
        </div>
        <form onSubmit={handleSubmit2(onSubmit2)} autoComplete="off">
          <FormGroup >
            <div hidden={step === 1} className="inputContainer" onChange={e => setEmail(e.target.value)}>
              <input
                type="email"
                name="Email"

                {...register2("email", {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t("Register.emailInvalid"),
                  },
                })}
              />
              <div className="label-champ">{t("Register.emailHint")}</div>
              {emailError && <div className="emailError">Your Email address exist already ! please contact the  <Mailto email="contact@mymotheragency.com" subject="Hello" body="Hello world!">
                admin</Mailto></div>}
              <div className="error">
                {errors2.email ? errors2.email && errors2.email.message : null}
              </div>
              <MailOutlineIcon className="icon" />
              <div className="label">{t("Register.email")}</div>
            </div>
            <div hidden={step === 1} className="inputContainer">
              <input
                type={passwordShown ? "text" : "password"}
                name="password"
                pattern="^(?=.{6,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$"
                {...register2("password", { required: true })}
              />
              <div className="label-champ"> {t("Register.passwordHint")}</div>
              {passwordShown ? (
                <VisibilityIcon onClick={togglePassword} className="icon" />
              ) : (
                <VisibilityOffIcon onClick={togglePassword} className="icon" />
              )}
              <div className="label">{t("Register.password")}</div>
            </div>
            <div className="error">
              {" "}
              {errors2.password && t("Register.passwordError")}
            </div>
          </FormGroup>
          <button id="form2" className="hideButton"></button>
        </form>
      </div>
    </>
  );

  ////////////////////////////////////////////////////////
  ////////        Google Map Implementation      ////////
  //////////////////////////////////////////////////////

  ////////        Default Map Position      ////////
  let [center, setDefaultProps] = useState({
    lat: 48.831617,
    lng: 2.37854,
  });

  //https://positionstack.com
  const apiKey = "14f426956b383729d0745089ace48115";
  //google api Key
  const googleApiKey = "AIzaSyB_UnFeiA5IUUYaYpCl35Mg6CF9WJ0w--c";

  const _onClick = ({ x, y, lat, lng, event }) => {
    axios
      .get(
        `http://api.positionstack.com/v1/reverse?access_key=${apiKey}&query=${lat},${lng}`
      )
      .then((res) => {
        setRegion(`${res.data.data[0].region}, ${res.data.data[0].country}`);
        setErrorRegion(false);
      });
  };
  const findPays = () => {
    let country = document.getElementById("country").value;
    axios
      .get(
        `http://api.positionstack.com/v1/forward?access_key=${apiKey}&query= ${country}`
      )
      .then((response) => {
        if (response.data.data[0]) {
          setErrorPosition(false);
          setDefaultProps({
            lat: response.data.data[0].latitude,
            lng: response.data.data[0].longitude,
          });
        } else {
          setErrorPosition(true);
        }
      })
      .catch((error) => {
      });
  };

  useEffect(() => {
    setRegion(addresse);
  }, [addresse]);
  const [spins, setSpins] = useState(false);

  ///////////////////////////////////////////////////////////////
  ////////          Gender Selection function           ////////
  /////////////////////////////////////////////////////////////

  const handleSelectGender = (e) => {
    setGender(e.target.value);
  };


  ///////////////////////////////////////////////////////////////
  ////////        FinalForm Data to send to Firebase    ////////
  /////////////////////////////////////////////////////////////

  const submitForm = () => {
  };

  const submitFormFinal = () => {
    alert(JSON.stringify(formData));
  };
  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  /*Use like so*/
  const sleeps = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  async function timeSensativeAction() {
    //must be async func

    //do something here
    await sleep(5000); //wait 5 seconds//set the state to true
    //continue on...
  }

  const [tokens, setTokens] = useState(null);
  const handleMMpartner = () => {
    if (Africa.includes(region)) {
      setMMP("Africa");
    } else if (enSouthAsia.includes(region)) {
      setMMP("India");
    } else if (NorthAmerica.includes(region)) {
      setMMP("North America");
    }
  };
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    if (finalResult) {
      ////////////////////////////
      ////    Test Canals    ////
      //////////////////////////

      // Get Firebase Database
      const db = getDatabase();

      // Get Firebase Auth for User and UID
      // const auth = getAuth();

      // Setting Up Relation between Collection and User
      const usersRef = ref(db, "users");
      const modelsRef = ref(db, "data/models");
      const newUserRef = push(usersRef);
      const newModelRef = push(modelsRef);
      //dataTest.data.models.MMPartner = "Africa"
      if (uid.current && firstName.current === "" && lastName.current === "") {

        // Setting UID for current Path
        let userUid = searchParams.get("uid");

        dataTest.users.createdAt = dayjs().unix();
        dataTest.data.models.createdAt = dayjs().unix();
        dataTest.data.models.addressPackage.country = formData.region;
        // formData.hasCompletedSubscribe = true;
        // formData.isRefunded = false;
        delete formData.password;

        //  Set DataTest with subscription
        dataTest.users.subscriptions = subscriptions;
        //  Set dataTest with User formData
        dataTest.data.models.birthday = formData.dateNaissance;
        dataTest.data.models.email = formData.email;
        dataTest.data.models.firstName = formData.firstName;
        dataTest.data.models.lastName = formData.lastName;
        dataTest.data.models.gender = formData.gender;
        if (Africa.includes(formData.region))
          dataTest.data.models.MMPartner = "Africa";
        else if (enSouthAsia.includes(formData.region))
          dataTest.data.models.MMPartner = "India";
        else if (NorthAmerica.includes(formData.region))
          dataTest.data.models.MMPartner = "North America";

        getAuth()
          .getUser(userUid)
          .then((userRecord) => {
            // See the UserRecord reference doc for the contents of userRecord.
            const user = userRecord;
            if (user) {
              getIdToken(user).then(async (token) => {
                await set(ref(db, "users/" + userUid), dataTest.users);
                await set(
                  ref(db, "data/models/" + userUid),
                  dataTest.data.models
                );
                await set(
                  ref(db, "users/" + userUid + "/subscriptions"),
                  dataTest.users.subscriptions
                );
                setFinalResult(false);
                setTokens(token);
                setSpins(true);
              });
            } else {
              set(newUserRef, dataTest.users);
              set(newModelRef, dataTest.data.models);
            }
          })
          .catch((error) => {
          });
      } else if (
        searchParams.get("uid") &&
        searchParams.get("firstName") &&
        searchParams.get("lastName")
      ) {

        // Setting UID for current Path
        let userUid = searchParams.get("uid");

        dataTest.users.createdAt = dayjs().unix();
        dataTest.data.models.createdAt = dayjs().unix();
        // formData.hasCompletedSubscribe = true;
        // formData.isRefunded = false;
        delete formData.password;

        //  Set DataTest with subscription
        dataTest.users.subscriptions = subscriptions;

        //  Set dataTest with User formData
        // dataTest.data.models.birthday = formData.dateNaissance;
        dataTest.data.models.email = searchParams.get("mail");
        // dataTest.data.models.firstName = formData.firstName;
        // dataTest.data.models.lastName = formData.lastName;
        dataTest.data.models.gender = formData.gender;
        if (Africa.includes(formData.region))
          dataTest.data.models.MMPartner = "Africa";
        else if (enSouthAsia.includes(formData.region))
          dataTest.data.models.MMPartner = "India";
        else if (NorthAmerica.includes(formData.region))
          dataTest.data.models.MMPartner = "North America";


        set(ref(db, "users/" + userUid), dataTest.users);
        set(ref(db, "data/models/" + userUid), dataTest.data.models);
        set(
          ref(db, "users/" + userUid + "/subscriptions"),
          dataTest.users.subscriptions
        );

        setFinalResult(false);
        //window.ReactNativeWebView.postMessage(token);
      } else {

        const auth = getAuth();

        createUserWithEmailAndPassword(auth, formData.email, formData.password)
          .then((userCredential) => {
            // Signed in
            const user = userCredential.user;

            dataTest.users.createdAt = dayjs().unix();
            dataTest.data.models.createdAt = dayjs().unix();
            // formData.hasCompletedSubscribe = true;
            // formData.isRefunded = false;
            delete formData.password;
            dataTest.users.trialPeriod = 1;
            //  Set DataTest with subscription
            ////REMETTRE LES 3 CI-DESSOUS QUAND VIA STRIPE ET PAS LE VOUCHER
            // use "===" to compare the value of the subscription
            if (subscriptions.type === "stripe")
              dataTest.users.stripeCustomerId = formData.stripeCustomerId;
            if (
              subscriptions.type === "stripe" ||
              subscriptions.type == "paypal"
            ) {
              dataTest.users.subscriptions = subscriptions;
              dataTest.users.paymentValidation = true;
              dataTest.users.paymentValidationRequired = false;
              dataTest.users.paymentValidationAt = dayjs()
                .add(1, "month")
                .valueOf();
            }
            //  Set dataTest with User formData
            dataTest.data.models.birthday = formData.dateNaissance;
            dataTest.data.models.email = formData.email;
            dataTest.data.models.firstName = formData.firstName;
            dataTest.data.models.lastName = formData.lastName;
            dataTest.data.models.gender = formData.gender;
            if (Africa.includes(formData.region))
              dataTest.data.models.MMPartner = "Africa";
            else if (enSouthAsia.includes(formData.region))
              dataTest.data.models.MMPartner = "India";
            else if (NorthAmerica.includes(formData.region))
              dataTest.data.models.MMPartner = "North America";

            if (user) {
              getIdToken(user).then(async () => {
                await set(ref(db, "users/" + user.uid), dataTest.users);
                await set(
                  ref(db, "data/models/" + user.uid),
                  dataTest.data.models
                ); // manque catégorie expérience expérience
                // set(ref(db,'users/'+ user.uid + '/subscriptions'), dataTest.users.subscriptions); // OK

                await push(
                  ref(db, "users/" + user.uid + "/subscriptions"),
                  subscriptions
                );
                await set(ref(db, "groups/model/users/" + user.uid), true);

                // Doublon de Aymen pour test la data sur Firebase
                // set(ref(db,'data/models/'+ user.uid + '/subscriptions'), dataTest.users.subscriptions);

                setFinalResult(false);
                // window.ReactNativeWebView.postMessage(token);
                const data = {
                  uid: user.uid,
                };
                axios
                  .post(
                    "https://us-central1-mymotheragency-208817.cloudfunctions.net/token/generate",
                    data
                  )
                  .then((res) => {
                    setTokens(res.data);
                    setSpins(true);
                  });
              });
            } else {
              set(newUserRef, dataTest.users);
              set(newModelRef, dataTest.data.models);
            }

          })
          .catch((error) => {
            // ..
          });
      }
    }
  }, [finalResult]);

  //////////////////////////////////////////////////////////////////
  ////////                 Voucher Validation              ////////
  ////////////////////////////////////////////////////////////////
  const [voucherObject, setVoucherObject] = useState();
  const _checkVoucher = (e) => {
    axios
      .get(
        `https://app.mymotheragency.com/api/voucher/isvalid/${e.target.value}`
      )
      .then((res) => {
        if (res.data.isValid) {
          errors4.voucher = false;
          setVoucherObject(res.data);
          if (active) {
            if (res.data.talentLab.toString() == "true") {
              setIsValidVoucher(true);
              setVoucher(e.target.value);

            }
            else {
              errors4.voucher = true;
              setIsValidVoucher(false);
              setVoucher("");
            }
          } else {
            setIsValidVoucher(true)
            setVoucher(e.target.value);
          }
          if (res.data.voucherUid === "MMAxELLE") {
            setStripePlan("price_1LHRp4EehZRn7Ri6UgbW8M6r");
            setPpPlanId("P-6VL23162U82506710MLAYVKY");
          }
          if (
            Africa.includes(formData.region) &&
            (res.data.voucherUid === "MMAxRentrée" ||
              res.data.voucherUid === "MMAxCameroun" ||
              res.data.voucherUid === "MMAxSenegal")
          ) {
            setStripePlan("price_1LamCqEehZRn7Ri6RVbXPl8H");
            setPpPlanId("P-8U356294EH471083TMMD5J4Y");
          }
          if (res.data.voucherUid === "MMAxCIV") {
            setStripePlan("price_1LSS6sEehZRn7Ri6TDNgpo4O");
            setPpPlanId("P-4FV32617JH0730310MLUY7BY");
          }
          if (res.data.voucheruid === "studytalent") {
            setStripePlan("plan_DtoPchVvkaYVJy");
          }
        } if (subInfo.plan === 0 && res?.data?.talentLab?.toString() == "true") {
          errors4.voucher = true;
          setIsValidVoucher(false);
          setVoucher("");
        }
      })
    // .catch((error) =>return; console.error("Error", error));
  };

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  const sendVoucher = () => {
    dataTest.users.trialPeriod = 9999;
    let beginAt = new Date();
    let endAt = beginAt;
    let period = voucherObject.period;
    setSubscriptions((prev) => ({
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime(),
      beginAt: new Date(beginAt).getTime(),
      endAt: new Date(addDays(endAt, period)).getTime(),
      //endAt: new Date(endAt.setMonth(endAt.getMonth() + 12)).getTime(),
      type: "voucher",
      subscription: { voucherUid: voucher },
    }));
    if (Africa.includes(formData.region))
      dataTest.data.models.MMPartner = "Africa";
    else if (enSouthAsia.includes(formData.region))
      dataTest.data.models.MMPartner = "India";
    else if (NorthAmerica.includes(formData.region))
      dataTest.data.models.MMPartner = "North America";

    if (active) {
      setTalentLabBool(true);
    }
    // subscriptions.createdAt = new Date().getTime();
    // subscriptions.updatedAt = new Date().getTime();
    // subscriptions.beginAt = new Date(beginAt).getTime();
    // subscriptions.endAt = new Date(endAt.setMonth(endAt.getMonth() + 12)).getTime();
    // subscriptions.type = 'voucher';
    // subscriptions.subscription.voucherUid = voucher;
    setIsItFinished(true);
    setFinalResult(true);
  };
  //////////////////////////////////////////////////////////////////
  ////////        Stripe CheckOut and Final Validation     ////////
  ////////////////////////////////////////////////////////////////
  // Spinner Div hide
  var mainDiv = document.getElementById("main");
  const handleTokenPaypal2 = async (data, subscription) => {
    // Spinner Div hide
    mainDiv.classList.add("hide");
    // SetUp Subscription Data
    let beginAt = new Date();
    let endAt = beginAt;
    subscriptions.createdAt = new Date().getTime();
    subscriptions.updatedAt = new Date().getTime();
    subscriptions.beginAt = new Date(beginAt).getTime();
    subscriptions.endAt = new Date(
      endAt.setMonth(endAt.getMonth() + 12)
    ).getTime();
    if (subscription) subscriptions.subscription = subscription.subscription;
    subscriptions.type = "paypal";
    // formData.subscriptions = subscriptions;
    if (Africa.includes(formData.region))
      dataTest.data.models.MMPartner = "Africa";
    else if (enSouthAsia.includes(formData.region))
      dataTest.data.models.MMPartner = "India";
    else if (NorthAmerica.includes(formData.region))
      dataTest.data.models.MMPartner = "North America";
    if (active) {
      setTalentLabBool(true);
    }
    setIsItFinished(true);
    setFinalResult(true);
  };
  const handleTokenRazorpay = async (data, subscription) => {
    // Spinner Div hide
    mainDiv.classList.add("hide");
    // SetUp Subscription Data
    let beginAt = new Date();
    let endAt = beginAt;
    subscriptions.createdAt = new Date().getTime();
    subscriptions.updatedAt = new Date().getTime();
    subscriptions.beginAt = new Date(beginAt).getTime();
    subscriptions.endAt = new Date(
      endAt.setMonth(endAt.getMonth() + 12)
    ).getTime();
    if (subscription) subscriptions.subscription = subscription.subscription;
    subscriptions.type = "razorpay";
    // formData.subscriptions = subscriptions;
    if (Africa.includes(formData.region))
      dataTest.data.models.MMPartner = "Africa";
    else if (enSouthAsia.includes(formData.region))
      dataTest.data.models.MMPartner = "India";
    else if (NorthAmerica.includes(formData.region))
      dataTest.data.models.MMPartner = "North America";
    if (active) {
      setTalentLabBool(true);
    }
  };

  const handleToken = async (status, subscription) => {
    setStripeIsOpen(false);

    if (subscription.status !== "active") {
      subscription = await axios.post(
        "https://us-central1-mymotheragency-208817.cloudfunctions.net/checkoutSocial/retrieve",
        { subscriptionId: subscription.id }
      );
      subscription = subscription.data;
    }
    // Spinner Div hide
    mainDiv.classList.add("hide");

    // SetUp Subscription Data
    let beginAt = new Date();
    let endAt = beginAt;
    subscriptions.createdAt = new Date().getTime();
    subscriptions.updatedAt = new Date().getTime();
    subscriptions.beginAt = new Date(beginAt).getTime();
    subscriptions.endAt = new Date(
      endAt.setMonth(endAt.getMonth() + 12)
    ).getTime();

    if (subscription.customer) {
      formData.stripeCustomerId = subscription.customer;
    }
    subscriptions.subscription = subscription;
    subscriptions.type = "stripe";
    if (Africa.includes(formData.region))
      dataTest.data.models.MMPartner = "Africa";
    else if (enSouthAsia.includes(formData.region))
      dataTest.data.models.MMPartner = "India";
    else if (NorthAmerica.includes(formData.region))
      dataTest.data.models.MMPartner = "North America";
    if (active) {
      setTalentLabBool(true);
    }
    setIsItFinished(true);
    setFinalResult(true);
  };

  //////////////////////////////////////////////////////////////////
  ////////    Loading Component After Payment Validation   ////////
  ////////////////////////////////////////////////////////////////
  // create a list of all european countries
  // const countries = {
  //   AF: "Afghanistan",
  //   AL: "Albania",
  //   DZ: "Algeria",
  //   AS: "American Samoa",
  //   AD: "Andorra",
  //   AO: "Angola",
  //   AI: "Anguilla",
  //   AG: "Antigua and Barbuda",
  //   AR: "Argentina",
  //   AM: "Armenia",
  //   AW: "Aruba",
  //   AU: "Australia",
  //   AT: "Austria",
  //   AZ: "Azerbaijan",
  //   BS: "Bahamas",
  //   BH: "Bahrain",
  //   BD: "Bangladesh",
  //   BB: "Barbados",
  //   BY: "Belarus",
  //   BE: "Belgium",
  //   BZ: "Belize",
  //   BJ: "Benin",
  //   BM: "Bermuda",
  //   BT: "Bhutan",
  //   BO: "Bolivia, Plurinational State of",
  //   BA: "Bosnia and Herzegovina",
  //   BW: "Botswana",
  //   BR: "Brazil",
  //   IO: "British Indian Ocean Territory",
  //   BG: "Bulgaria",
  //   BF: "Burkina Faso",
  //   BI: "Burundi",
  //   KH: "Cambodia",
  //   CM: "Cameroon",
  //   CA: "Canada",
  //   CV: "Cape Verde",
  //   KY: "Cayman Islands",
  //   CF: "Central African Republic",
  //   TD: "Chad",
  //   CL: "Chile",
  //   CN: "China",
  //   CO: "Colombia",
  //   KM: "Comoros",
  //   CG: "Congo",
  //   CD: "Democratic Republic of the Congo",
  //   CK: "Cook Islands",
  //   CR: "Costa Rica",
  //   CI: "Côte d'Ivoire",
  //   HR: "Croatia",
  //   CU: "Cuba",
  //   CW: "Curaçao",
  //   CY: "Cyprus",
  //   CZ: "Czech Republic",
  //   DK: "Denmark",
  //   DJ: "Djibouti",
  //   DM: "Dominica",
  //   DO: "Dominican Republic",
  //   EC: "Ecuador",
  //   EG: "Egypt",
  //   SV: "El Salvador",
  //   GQ: "Equatorial Guinea",
  //   ER: "Eritrea",
  //   EE: "Estonia",
  //   ET: "Ethiopia",
  //   FK: "Falkland Islands (Malvinas)",
  //   FO: "Faroe Islands",
  //   FJ: "Fiji",
  //   FI: "Finland",
  //   FR: "France",
  //   PF: "French Polynesia",
  //   GA: "Gabon",
  //   GM: "Gambia",
  //   GE: "Georgia",
  //   DE: "Germany",
  //   GH: "Ghana",
  //   GI: "Gibraltar",
  //   GR: "Greece",
  //   GL: "Greenland",
  //   GD: "Grenada",
  //   GU: "Guam",
  //   GT: "Guatemala",
  //   GG: "Guernsey",
  //   GN: "Guinea",
  //   GW: "Guinea-Bissau",
  //   HT: "Haiti",
  //   HN: "Honduras",
  //   HK: "Hong Kong",
  //   HU: "Hungary",
  //   IS: "Iceland",
  //   IN: "India",
  //   ID: "Indonesia",
  //   IR: "Iran, Islamic Republic of",
  //   IQ: "Iraq",
  //   IE: "Ireland",
  //   IM: "Isle of Man",
  //   IL: "Israel",
  //   IT: "Italy",
  //   JM: "Jamaica",
  //   JP: "Japan",
  //   JE: "Jersey",
  //   JO: "Jordan",
  //   KZ: "Kazakhstan",
  //   KE: "Kenya",
  //   KI: "Kiribati",
  //   KP: "North Korea",
  //   KR: "South Korea",
  //   KW: "Kuwait",
  //   KG: "Kyrgyzstan",
  //   LA: "Lao People's Democratic Republic",
  //   LV: "Latvia",
  //   LB: "Lebanon",
  //   LS: "Lesotho",
  //   LR: "Liberia",
  //   LY: "Libya",
  //   LI: "Liechtenstein",
  //   LT: "Lithuania",
  //   LU: "Luxembourg",
  //   MO: "Macao",
  //   MK: "Republic of Macedonia",
  //   MG: "Madagascar",
  //   MW: "Malawi",
  //   MY: "Malaysia",
  //   MV: "Maldives",
  //   ML: "Mali",
  //   MT: "Malta",
  //   MH: "Marshall Islands",
  //   MQ: "Martinique",
  //   MR: "Mauritania",
  //   MU: "Mauritius",
  //   MX: "Mexico",
  //   FM: "Micronesia, Federated States of",
  //   MD: "Republic of Moldova",
  //   MC: "Monaco",
  //   MN: "Mongolia",
  //   ME: "Montenegro",
  //   MS: "Montserrat",
  //   MA: "Morocco",
  //   MZ: "Mozambique",
  //   MM: "Myanmar",
  //   NA: "Namibia",
  //   NR: "Nauru",
  //   NP: "Nepal",
  //   NL: "Netherlands",
  //   NZ: "New Zealand",
  //   NI: "Nicaragua",
  //   NE: "Niger",
  //   NG: "Nigeria",
  //   NU: "Niue",
  //   NF: "Norfolk Island",
  //   MP: "Northern Mariana Islands",
  //   NO: "Norway",
  //   OM: "Oman",
  //   PK: "Pakistan",
  //   PW: "Palau",
  //   PS: "Palestinian Territory",
  //   PA: "Panama",
  //   PG: "Papua New Guinea",
  //   PY: "Paraguay",
  //   PE: "Peru",
  //   PH: "Philippines",
  //   PN: "Pitcairn",
  //   PL: "Poland",
  //   PT: "Portugal",
  //   PR: "Puerto Rico",
  //   QA: "Qatar",
  //   RO: "Romania",
  //   RU: "Russia",
  //   RW: "Rwanda",
  //   KN: "Saint Kitts and Nevis",
  //   LC: "Saint Lucia",
  //   WS: "Samoa",
  //   SM: "San Marino",
  //   ST: "Sao Tome and Principe",
  //   SA: "Saudi Arabia",
  //   SN: "Senegal",
  //   RS: "Serbia",
  //   SC: "Seychelles",
  //   SL: "Sierra Leone",
  //   SG: "Singapore",
  //   SX: "Sint Maarten",
  //   SK: "Slovakia",
  //   SI: "Slovenia",
  //   SB: "Solomon Islands",
  //   SO: "Somalia",
  //   ZA: "South Africa",
  //   SS: "South Sudan",
  //   ES: "Spain",
  //   LK: "Sri Lanka",
  //   SD: "Sudan",
  //   SR: "Suriname",
  //   SZ: "Swaziland",
  //   SE: "Sweden",
  //   CH: "Switzerland",
  //   SY: "Syria",
  //   TW: "Taiwan",
  //   TJ: "Tajikistan",
  //   TZ: "Tanzania",
  //   TH: "Thailand",
  //   TG: "Togo",
  //   TK: "Tokelau",
  //   TO: "Tonga",
  //   TT: "Trinidad and Tobago",
  //   TN: "Tunisia",
  //   TR: "Turkey",
  //   TM: "Turkmenistan",
  //   TC: "Turks and Caicos Islands",
  //   TV: "Tuvalu",
  //   UG: "Uganda",
  //   UA: "Ukraine",
  //   AE: "United Arab Emirates",
  //   GB: "United Kingdom",
  //   US: "United States",
  //   UY: "Uruguay",
  //   UZ: "Uzbekistan",
  //   VU: "Vanuatu",
  //   VE: "Venezuela, Bolivarian Republic of",
  //   VN: "Viet Nam",
  //   VI: "Virgin Islands",
  //   YE: "Yemen",
  //   ZM: "Zambia",
  //   ZW: "Zimbabwe",
  // };
  const Europe = [
    "Albania",
    "Andorra",
    "Armenia",
    "Austria",
    "Azerbaijan",
    "Belarus",
    "Belgium",
    "Bosnia and Herzegovina",
    "Bulgaria",
    "Croatia",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Estonia",
    "Finland",
    "France",
    "Georgia",
    "Germany",
    "Greece",
    "Hungary",
    "Iceland",
    "Ireland",
    "Italy",
    "Kosovo",
    "Latvia",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macedonia",
    "Malta",
    "Moldova",
    "Monaco",
    "Montenegro",
    "Netherlands",
    "Norway",
    "Poland",
    "Portugal",
    "Romania",
    "Russia",
    "San Marino",
    "Serbia",
    "Slovakia",
    "Slovenia",
    "Spain",
    "Sweden",
    "Switzerland",
    "Turkey",
    "Ukraine",
    "United Kingdom",
    "Vatican City",
  ];
  const NorthAmerica = [
    "Canada",
    "USA",
    "United states",
    "United States",
    "United states of America",
    "United States of America",
  ];
  const Africa = [
    "Côte d’Ivoire",
    "Segal",
    "Sénégal",
    "Guinée Conakry",
    "Guinée",
    "Ghana",
    "Congo Brazzaville",
    "Democratic Republic of the Congo",
    "Rwanda",
    "Libéria",
    "Cameroun",
    "Tunisie",
    "Algérie",
    "Morocco",
  ];
  const SouthAsia = [
    "Inde",
    "Malaysia",
    "Singapore",
    "Pakistan",
    "Bangladesh",
    "Népal",
    "Sri Lanka",
  ];
  const enSouthAsia = [
    "India",
    "Inde",
    "Malaisie",
    "Singapour",
    "Pakistan",
    "Bangladesh",
    "Nepal",
    "Sri Lanka",
  ];

  const [active, setActive] = useState(false);
  const [priceClassic, setPriceClassic] = useState("35€");
  const [priceLab, setPriceLab] = useState("300€");
  //useeffect hook to load the component after payment validation
  useEffect(() => {
    if (formData.region) {
      if (NorthAmerica.includes(formData.region)) {
        setPriceClassic("49$");
        setPriceLab("395$");
      } else if (enSouthAsia.includes(formData.region)) {
        setPriceLab("₹ 7,999.00");
        setPriceClassic("₹ 3,649.00");
      } else if (Africa.includes(formData.region)) {
        setPriceClassic("23000CFA");
        setPriceLab("197000CFA");
      }
    }
  }, [formData.region]);
  const spinner = () => {

    return (
      <>
        <div className="d-flex">
          <div className="spinner-border" role="status">
            <span className="hide">Loading...</span>
          </div>
        </div>
      </>
    );
  };
  const landingPage = (
    <>
      <div id="lastPage">
        <h2>{t("Register.congrats")}</h2>
        <p>
          {!spins ? t("Register.congratsDesc2") : t("Register.congratsDesc")}
        </p>
        {!spins ? (
          spinner()
        ) : (
          <div className="confirmButtons" style={{ width: "100%" }}>
            <button
              onClick={() => {
                window.ReactNativeWebView.postMessage(
                  tokens != null ? tokens : ""
                );
              }}
              type="button"
              style={{ width: "50%" }}
            >
              {t("Register.goToApp2")}
            </button>
            <button
              onClick={() => {
                window.location.href = "https://onelink.to/yvewaa";
              }}
              type="button"
              style={{ width: "50%" }}
            >
              {t("Register.goToApp")}
            </button>
          </div>
        )}
      </div>
    </>
  );
  const [isOpen, setIsOpen] = useState(false);
  //stripe open is assigned but is never used
  const [stripeIsOpen, setStripeIsOpen] = useState(false);
  const [textBtn1, setTextBtn1] = useState(t("Register.packBtn"));
  const [textBtn2, setTextBtn2] = useState(t("Register.packBtn"));
  const [ppPlanId, setPpPlanId] = useState("P-8PE50048JF9406144MKYN7ZY");
  const [stripePlan, setStripePlan] = useState("plan_FNFbFW6DjaNtIe");
  const [showGodFather, setShowGodFather] = useState(false);
  useEffect(() => {
    if (active) {
      setTextBtn1(t("Register.packBtn"));
      setTextBtn2(t("Register.packBtnSelected"));
    } else {
      setTextBtn1(t("Register.packBtnSelected"));
      setTextBtn2(t("Register.packBtn"));
    }
  }, [active]);

  useEffect(() => {
    if (active) {
      if (NorthAmerica.includes(formData.region)) {
        setPpPlanId("P-9MU762897A4722231MKSOQPA");
        setStripePlan("price_1L9ZjxEehZRn7Ri6QIEW6wNO");
      } else if (SouthAsia.includes(formData)) {
        setPpPlanId("P-4D199730N2498802VMKSOT5Y");
        setStripePlan("price_1L9ZlyEehZRn7Ri6uMVB9lCz");
      } else if (Africa.includes(formData.region)) {
        setPpPlanId("P-4D199730N2498802VMKSOT5Y ");
        setStripePlan("price_1LASw5EehZRn7Ri6YHXnXuZu");
      } else {
        setPpPlanId("P-4D199730N2498802VMKSOT5Y");
        setStripePlan("price_1L9ZlyEehZRn7Ri6uMVB9lCz");
      }
    } else {
      if (NorthAmerica.includes(formData.region)) {
        setPpPlanId("P-1CH13741E6880750AMKYN6ZQ");
        setStripePlan("price_1L7zVBEehZRn7Ri6LUsJatf4");
      } else if (SouthAsia.includes(formData.region)) {
        setPpPlanId("P-8PE50048JF9406144MKYN7ZY");
        setStripePlan("plan_FNFbFW6DjaNtIe");
      } else if (Africa.includes(formData.region)) {
        setPpPlanId("P-3UB48616SY024182KMJIVEZY");
        setStripePlan("price_1LATeBEehZRn7Ri6lYuCZV3v");
      } else {
        setPpPlanId("P-8PE50048JF9406144MKYN7ZY");
        setStripePlan("plan_FNFbFW6DjaNtIe");
      }
    }
  }, [formData.region]);

  useEffect(() => {
    if (active) {
      if (NorthAmerica.includes(formData.region)) {
        setPpPlanId("P-9MU762897A4722231MKSOQPA");
        setStripePlan("price_1L9ZjxEehZRn7Ri6QIEW6wNO");
      } else if (SouthAsia.includes(formData)) {
        setPpPlanId("P-4D199730N2498802VMKSOT5Y");
        setStripePlan("price_1L9ZlyEehZRn7Ri6uMVB9lCz");
      } else if (Africa.includes(formData.region)) {
        setPpPlanId("P-4D199730N2498802VMKSOT5Y ");
        setStripePlan("price_1L9ZlyEehZRn7Ri6uMVB9lCz");
      } else {
        setPpPlanId("P-4D199730N2498802VMKSOT5Y");
        setStripePlan("price_1L9ZlyEehZRn7Ri6uMVB9lCz");
      }
    } else {
      if (NorthAmerica.includes(formData.region)) {
        setPpPlanId("P-1CH13741E6880750AMKYN6ZQ");
        setStripePlan("price_1L7zVBEehZRn7Ri6LUsJatf4");
      } else if (SouthAsia.includes(formData.region)) {
        setPpPlanId("P-8PE50048JF9406144MKYN7ZY");
        setStripePlan("plan_FNFbFW6DjaNtIe");
      } else if (Africa.includes(formData.region)) {
        setPpPlanId("P-3UB48616SY024182KMJIVEZY");
        setStripePlan("price_1KoQ6EEehZRn7Ri6Uove1Pfl");
      } else {
        setPpPlanId("P-8PE50048JF9406144MKYN7ZY");
        setStripePlan("plan_FNFbFW6DjaNtIe");
      }
    }
  }, [active]);
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const toggleStripe = () => {
    togglePopup();
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const saveUserData = () => {

    const db = getDatabase();
    const usersRef = ref(db, "users");
    const modelsRef = ref(db, "data/models");
    const newUserRef = push(usersRef);
    const newModelRef = push(modelsRef);
    if (uid.current && firstName.current === "" && lastName.current === "") {

      // Setting UID for current Path
      let userUid = searchParams.get("uid");

      dataTest.users.createdAt = dayjs().unix();
      dataTest.data.models.createdAt = dayjs().unix();
      dataTest.data.models.addressPackage.country = formData.region;
      // formData.hasCompletedSubscribe = true;
      // formData.isRefunded = false;
      delete formData.password;

      //  Set DataTest with subscription
      dataTest.users.subscriptions = subscriptions;
      //  Set dataTest with User formData
      dataTest.data.models.birthday = formData.dateNaissance;
      dataTest.data.models.email = formData.email;
      dataTest.data.models.firstName = formData.firstName;
      dataTest.data.models.lastName = formData.lastName;
      dataTest.data.models.gender = formData.gender;
      if (Africa.includes(formData.region))
        dataTest.data.models.MMPartner = "Africa";
      else if (enSouthAsia.includes(formData.region))
        dataTest.data.models.MMPartner = "India";
      else if (NorthAmerica.includes(formData.region))
        dataTest.data.models.MMPartner = "North America";
      getAuth()
        .getUser(userUid)
        .then((userRecord) => {
          // See the UserRecord reference doc for the contents of userRecord.

          const user = userRecord;


          if (user) {
            getIdToken(user).then(async (token) => {
              await set(ref(db, "users/" + userUid), dataTest.users);
              await set(
                ref(db, "data/models/" + userUid),
                dataTest.data.models
              );
              await set(
                ref(db, "users/" + userUid + "/subscriptions"),
                dataTest.users.subscriptions
              );

              setFinalResult(false);
              setTokens(token);
              setSpins(true);
            });
          } else {
            set(newUserRef, dataTest.users);
            set(newModelRef, dataTest.data.models);
          }
        })
        .catch((error) => {
        });
    } else if (
      searchParams.get("uid") &&
      searchParams.get("firstName") &&
      searchParams.get("lastName")
    ) {

      // Setting UID for current Path
      let userUid = searchParams.get("uid");

      dataTest.users.createdAt = dayjs().unix();
      dataTest.data.models.createdAt = dayjs().unix();
      // formData.hasCompletedSubscribe = true;
      // formData.isRefunded = false;
      delete formData.password;

      //  Set DataTest with subscription
      dataTest.users.subscriptions = subscriptions;

      //  Set dataTest with User formData
      // dataTest.data.models.birthday = formData.dateNaissance;
      dataTest.data.models.email = searchParams.get("mail");
      // dataTest.data.models.firstName = formData.firstName;
      // dataTest.data.models.lastName = formData.lastName;
      dataTest.data.models.gender = formData.gender;
      if (Africa.includes(formData.region))
        dataTest.data.models.MMPartner = "Africa";
      else if (enSouthAsia.includes(formData.region))
        dataTest.data.models.MMPartner = "India";
      else if (NorthAmerica.includes(formData.region))
        dataTest.data.models.MMPartner = "North America";
      set(ref(db, "users/" + userUid), dataTest.users);
      set(ref(db, "data/models/" + userUid), dataTest.data.models);
      set(
        ref(db, "users/" + userUid + "/subscriptions"),
        dataTest.users.subscriptions
      );

      setFinalResult(false);
      //window.ReactNativeWebView.postMessage(token);
    } else {

      const auth = getAuth();

      createUserWithEmailAndPassword(auth, formData.email, formData.password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;

          dataTest.users.createdAt = dayjs().unix();
          dataTest.data.models.createdAt = dayjs().unix();
          // formData.hasCompletedSubscribe = true;
          // formData.isRefunded = false;
          delete formData.password;
          dataTest.users.trialPeriod = 1;
          //  Set DataTest with subscription
          ////REMETTRE LES 3 CI-DESSOUS QUAND VIA STRIPE ET PAS LE VOUCHER
          // use "===" to compare the value of the subscription
          if (subscriptions.type === "stripe")
            dataTest.users.stripeCustomerId = formData.stripeCustomerId;
          if (
            subscriptions.type === "stripe" ||
            subscriptions.type == "paypal" ||
            subscriptions.type == "razorpay"
          ) {
            dataTest.users.subscriptions = subscriptions;
            dataTest.users.paymentValidation = true;
            dataTest.users.paymentValidationRequired = false;
            dataTest.users.paymentValidationAt = dayjs()
              .add(1, "month")
              .valueOf();
          }
          //  Set dataTest with User formData
          dataTest.data.models.birthday = formData.dateNaissance;
          dataTest.data.models.email = formData.email;
          dataTest.data.models.firstName = formData.firstName;
          dataTest.data.models.lastName = formData.lastName;
          dataTest.data.models.gender = formData.gender;
          if (Africa.includes(formData.region))
            dataTest.data.models.MMPartner = "Africa";
          else if (enSouthAsia.includes(formData.region))
            dataTest.data.models.MMPartner = "India";
          else if (NorthAmerica.includes(formData.region))
            dataTest.data.models.MMPartner = "North America";
          if (user) {
            getIdToken(user).then(async () => {
              await set(ref(db, "users/" + user.uid), dataTest.users);
              await set(
                ref(db, "data/models/" + user.uid),
                dataTest.data.models
              ); // manque catégorie expérience expérience
              // set(ref(db,'users/'+ user.uid + '/subscriptions'), dataTest.users.subscriptions); // OK

              await push(
                ref(db, "users/" + user.uid + "/subscriptions"),
                subscriptions
              );
              await set(ref(db, "groups/model/users/" + user.uid), true);

              // Doublon de Aymen pour test la data sur Firebase
              // set(ref(db,'data/models/'+ user.uid + '/subscriptions'), dataTest.users.subscriptions);

              setFinalResult(false);
              // window.ReactNativeWebView.postMessage(token);
              const data = {
                uid: user.uid,
              };
              axios
                .post(
                  "https://us-central1-mymotheragency-208817.cloudfunctions.net/token/generate",
                  data
                )
                .then((res) => {
                  setTokens(res.data);
                  setSpins(true);
                });
            });
          } else {
            set(newUserRef, dataTest.users);
            set(newModelRef, dataTest.data.models);
          }
        })
        .catch((error) => {
          // ..
        });
    }

  }

  async function displayRazorpay(_callback) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    let subData = { whichPlan: subInfo };
    //FIXME: fix the link
    const result = await axios.post(
      "https://us-central1-mymotheragency-208817.cloudfunctions.net/checkoutRazorpay/payment/orders",
      subInfo
    );

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }
    console.log('trace', result);
    const { amount, id, currency } = result.data.orderId;
    const options = {
      // key: "rzp_test_x4xuiZIaEw28e3", // Enter the Key ID generated from the Dashboard
      key: process.env.REACT_APP_RAZORPAY_KEY_LIVE, // Enter the Key ID generated from the Dashboard
      amount: amount,
      currency: currency,
      name: `${formData.firstName} ${formData.lastName}`,
      description: subInfo.plan === 0 ? "Talent classic" : "Talent lab",
      image: { logo },
      order_id: id,
      // subscription_id: id,
      handler: async function (response) {
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_subscription_id);
        // alert(response.razorpay_signature);
        const data = {
          orderCreationId: id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpay_subscription_id: response.razorpay_subscription_id,
          razorpaySignature: response.razorpay_signature,
        };

        //FIXME: fix the link
        let userUid = searchParams.get("uid");
        const db = getDatabase();
        const result = await axios.post(' https://us-central1-mymotheragency-208817.cloudfunctions.net/checkoutRazorpay/payment/success', data)
          .then(res => {
            setFormData((formDataState) => {
              return { ...formDataState, subscriptionId: res.data.subscriptionId, paymentId: res.data.paymentId };
            });
            _callback(res)
          }).then(saveUserData()).then(setIsItFinished(true)).catch(err => {
          })
      },
      prefill: {
        name: `${formData.firstName} ${formData.lastName}`,
        email: formData.email,
        contact: formData.phone,
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }
  const submitForms = () => {
    displayRazorpay(function () {
      setFinalResult(true);
    });
    handleTokenRazorpay()
    setCguBtn(false)

  };

  const [timer, setTimer] = useState(0);
  const [disabled, setDisabled] = useState(false);

  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );
  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  const resetTimer = () => {
    setDisabled(true);
    setTimer(59);
    setTimeout(() => setDisabled(false), 60000);
  };

  const sendTwilioCode = () => {
    axios
      .post(
        "https://us-central1-mymotheragency-208817.cloudfunctions.net/checkoutRazorpay/getCode",
        { phone: phone }
      )
      .then((res) => {
        ///start countdown resend!
        setErrorPhone(false);
      })
      .catch((err) => {
        setErrorPhone(true);
      });
  };
  const verifyTwilioCode = function () {
    let res = false;
    let theCode = document.getElementById("otp");
    theCode = theCode.value;

    return axios.post(
      "https://us-central1-mymotheragency-208817.cloudfunctions.net/checkoutRazorpay/verifyCode",
      { code: theCode, phone: phone }
    );
  };
  const verifyEmail = function () {
    // let user = formData.email
    return axios.post('https://us-central1-mymotheragency-208817.cloudfunctions.net/verifyUser/getUserInfo', { email: email })

  }
  // console.log(submitForms())
  console.log(mMP)
  console.log("the voucher is", voucher)
  console.log(isValidVoucher)

  return (
    <div>
      <div className="formContainer">
        <div className="left-area">
          <img src={slideImg} alt="slideImg" />
        </div>
        <div
          className="form"
          style={windowDimensions.width > 768 ? { position: "relative" } : null}
        >
          <img src={logo} alt="logo" />
          {isItFinished ? (
            landingPage
          ) : (
            <>
              <div id={"content"}>
                {finalResult ? spinner() : null}
                <div id={"main"} className="">
                  {searchParams.get("uid") &&
                    searchParams.get("firstName") &&
                    searchParams.get("lastName")
                    ? null
                    : form1And2}

                  {/* {enSouthAsia.includes(formData.region) &&   */}
                  <div
                    id="formThree"
                    className="formThree forms"
                    style={
                      step === 2 && enSouthAsia.includes(formData.region)
                        ? opacity
                        : null
                    }
                    hidden={
                      !enSouthAsia.includes(formData.region) ||
                      step < 2 ||
                      step > 3
                    }
                  >
                    <div id="formThreeName">
                      <span className="stepNumber">03</span>
                      <h2>{t("Register.codeVerificationStep")}</h2>
                    </div>
                    <form
                      onSubmit={handleSubmit6(onSubmit6)}
                      autoComplete="off"
                    >
                      <FormGroup>
                        <div className="error">
                          {errors6.position && errors6.position.message}
                        </div>
                        <div
                          hidden={step === 2}
                          className="inputContainer"
                          style={{
                            display: "flex",
                            alignItems: "stretch",
                          }}
                        >
                          <PhoneInput
                            {...register6("phone", {
                              required: true,
                            })}
                            // placeholder="Enter phone number"
                            value={phone}
                            className="fixphone"
                            onChange={setPhone}
                            international
                            style={{}}

                          // style={{ width: '10' }}
                          />
                          <button
                            className="sendTwilio"
                            style={{
                              width: "20%",
                              cursor: "pointer",
                              "border-radius": "14px",
                              "font-weight": "600",
                              border: "none",
                              "font-size": "16px",
                              "min-height": "33px",
                              "margin-left": "25px",
                              padding: "7px 12px",
                              position: "relative",
                              background: disabled
                                ? "linear-gradient(90deg, #ccc, #ccc) "
                                : "linear-gradient(90deg, #e73a88, #e4075f)",
                              color: "white",
                            }}
                            disabled={disabled}
                            onClick={(e) => {
                              sendTwilioCode();
                              resetTimer();
                              e.preventDefault();
                              setSentOtp(true);
                            }}
                          >
                            Send ({timer}){" "}
                          </button>
                          <div className="labelone">{t("Register.phone")}</div>
                        </div>
                        <div id="error_phone" className="error">
                          {/* {phone === "" ? t("Register.phoneFormatError") : ""} */}
                        </div>
                        {/* <div className="inputContainer" style={{ display: sentOtp ? 'block' : 'none' }}> */}
                        <div className="inputContainer">
                          <input
                            name="code"
                            type="text"
                            id="otp"
                            {...register6("code", {
                              // required: true,
                              pattern: /^[0-9]+$/,
                              maxLength: 6,
                              minLength: 6,
                            })}
                          />

                          <div className="label">{t("Register.code")}</div>
                        </div>
                        <div id="error_phone" className="error">
                          {errors6.code &&
                            errors6.code.type === "required" &&
                            t("Register.codeError")}
                          {errors6.code &&
                            (errors6.code.type === "pattern" ||
                              errors6.code.type === "minLength" ||
                              errors6.code.type === "maxLength") &&
                            t("Register.codeFormatError")}
                        </div>
                        <button id="form6" className="hideButton"></button>
                      </FormGroup>
                    </form>
                  </div>
                  {/* } */}
                  <div
                    id="form_three"
                    className="formThree forms"
                    style={
                      (step === 3 && enSouthAsia.includes(formData.region)) ||
                        (step === 2 && !enSouthAsia.includes(formData.region))
                        ? opacity
                        : null
                    }
                    hidden={
                      (enSouthAsia.includes(formData.region) &&
                        (step < 3 || step > 4)) ||
                      (!enSouthAsia.includes(formData.region) &&
                        (step < 2 || step > 3))
                    }
                  >
                    <div id="formThreeName">
                      <span className="stepNumber">
                        {enSouthAsia.includes(formData.region) ? "04" : "03"}
                      </span>
                      <h2>{t("Register.thirdStep")}</h2>
                    </div>

                    <form
                      onSubmit={handleSubmit3(onSubmit3)}
                      autoComplete="off"
                    >
                      <FormGroup>
                        <div className="error">
                          {errors3.position && errors3.position.message}
                        </div>
                        <div
                          hidden={
                            (step == 3 &&
                              enSouthAsia.includes(formData.region)) ||
                            (step == 2 &&
                              !enSouthAsia.includes(formData.region))
                          }
                          className="inputContainer"
                        >
                          <Input
                            id="genderSelect"
                            name="selectGender"
                            type="select"
                            value={gender}
                            onChange={handleSelectGender}
                            required={true}
                          >
                            <option value="" hidden></option>
                            <option value={"man"}>
                              {t("Register.genderMale")}
                            </option>
                            <option value={"woman"}>
                              {t("Register.genderFemale")}
                            </option>
                            <option value={"transgender"}>
                              {t("Register.genderTrans")}
                            </option>
                          </Input>
                          <div className="label">{t("Register.gender")}</div>

                          <div className="error"></div>
                        </div>

                        <div
                          hidden={
                            (step == 3 &&
                              enSouthAsia.includes(formData.region)) ||
                            (step == 2 &&
                              !enSouthAsia.includes(formData.region))
                          }
                          className="inputContainer"
                        >
                          <input
                            type="date"
                            id="birth"
                            name="date"
                            // pattern="/^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g"
                            required={true}
                            style={{ width: "100%" }}
                            placeholder="jj/mm/aaaa"
                          // onChange={(date) => setDateNaissance(date)}
                          />
                          <div className="label-champ">
                            {t("Register.birthDateHint")}{" "}
                          </div>

                          <DateRangeIcon
                            style={{ pointerEvents: "none" }}
                            className="icon"
                          />
                          <div className="label">{t("Register.birthDate")}</div>
                        </div>
                        <div id="error_birth" className="error">
                          {errorAge &&
                            (NorthAmerica.includes(formData.region)
                              ? t("Register.birthDateInvalid2")
                              : t("Register.birthDateInvalid"))}
                        </div>

                        <button
                          id="form3"
                          type="submit"
                          className="hideButton"
                        ></button>
                      </FormGroup>
                    </form>
                  </div>

                  <div
                    id="form_four"
                    className="formFour forms"
                    //  style={step === 4 ? opacity : null} hidden={step < 4 || step > 5}
                    style={
                      (step === 4 && enSouthAsia.includes(formData.region)) ||
                        (step === 3 && !enSouthAsia.includes(formData.region))
                        ? opacity
                        : null
                    }
                    hidden={
                      (enSouthAsia.includes(formData.region) &&
                        (step < 4 || step > 5)) ||
                      (!enSouthAsia.includes(formData.region) &&
                        (step < 3 || step > 4))
                    }
                  >
                    <div id="formFourName">
                      <span className="stepNumber">
                        {enSouthAsia.includes(formData.region) ? "05" : "04"}
                      </span>
                      <h2>{t("Register.fourthStep")}</h2>
                    </div>

                    <div className="scroll">
                      <Carousel
                        showArrows={false}
                        swipeable={true}
                        showStatus={false}
                        showThumbs={false}
                        emulateTouch={true}
                        autoPlay={false}
                        centerMode={true}
                        centerSlidePercentage={90}
                      >
                        <div className="offreDesc">
                          <div className={"centred"}>
                            <h2>
                              talents classic
                              <img src={classicImg} alt="Classic" />
                            </h2>
                          </div>
                          <p className="title">
                            <strong>{t("Register.pack1Title1")}</strong>
                          </p>
                          <p>{t("Register.pack1Description1")} </p>

                          <p className="title">
                            <strong>{t("Register.pack1Title2")}</strong>
                          </p>
                          <p> {t("Register.pack1Description2")}</p>

                          <p className="title">
                            <strong>{t("Register.pack1Title3")}</strong>
                          </p>
                          <p> {t("Register.pack1Description3")}</p>
                          <p className="title">
                            <strong>{t("Register.pack1Title4")}</strong>
                          </p>
                          <p> {t("Register.pack1Description4")}</p>
                          <p className="title">
                            <strong>{t("Register.pack1Title5")}</strong>
                          </p>
                          <p> {t("Register.pack1Description5")}</p>
                          <p className="title">
                            <strong>{t("Register.pack1Title6")}</strong>
                          </p>
                          <p> {t("Register.pack1Description6")}</p>
                          <p className="title">
                            <strong>{t("Register.pack1Title8")}</strong>
                          </p>
                          <p> {t("Register.pack1Description8")}</p>
                                               
                          {/* {!Africa.includes(formData.region) && (
                            <>
                              {" "}
                              <p className="title">
                                <strong>{t("Register.pack1Title4")}</strong>
                              </p>
                              <p> {t("Register.pack1Description4")}</p>
                            </>
                          )} */}

                          <div>
                            <h4 style={{ color: "white", marginLeft: 120 }}>
                              {priceClassic}
                              {t("Register.pack1Prix")}
                            </h4>
                            {/* {Africa.includes(formData.region) && (
                              <h4 style={{ color: "white", marginLeft: 120 }}>
                                20000CFA{t("Register.pack1Prix")}
                              </h4>
                            )} */}
                            <div className={"centred"}>
                              <button
                                className={
                                  !active ? "toChooseButton" : "sendButtonGrey"
                                }
                                onClick={() => {
                                  setActive(false);
                                  setSubInfo({ plan: 0 });
                                  arrowFunction()
                                  changeStep()
                                }}
                                type="button"
                              >
                                {textBtn1}
                              </button>
                            </div>
                          </div>
                        </div>
                        
                        {/* <div className="offreDesc">
                          <div className={"centred"}>
                            <h2>
                              talents lab
                              <img src={talentsImg} alt="Talentimg" />
                            </h2>
                          </div>
                          <p className="title">
                            <strong>{t("Register.pack2Title1")}</strong>
                          </p>
                          <p>{t("Register.pack2Description1")}</p>
                          <p className="title">
                            <strong>{t("Register.pack2Title2")}</strong>
                          </p>
                          <p>{t("Register.pack2Description2")}</p>
                          <p className="title">
                            <strong>{t("Register.pack2Title3")}</strong>
                          </p>
                          <p>{t("Register.pack2Description3")}</p>
                          <div>
                            <h4 style={{ color: "white", marginLeft: 120 }}>
                              {priceLab}

                              {t("Register.pack2Prix")}
                            </h4> */}
                            {/* <div className={"centred"}>
                              <button
                                className={
                                  active ? "toChooseButton" : "sendButtonGrey"
                                }
                                type="button"
                                onClick={() => {
                                  setActive(true);
                                  setSubInfo({ plan: 1 });
                                  arrowFunction()

                                }}
                              >
                                {textBtn2}
                              </button>
                            </div> */}
                            {/* <div className={"centred"}>
                              <button
                                className="sendButtonGrey"
                                type="button"
                              >
                                {t("Coming soon")}
                              </button>
                            </div>
                          </div>
                        </div> */}
                      </Carousel>
                    </div>
                  </div>
                  <div
                    id="form_five"
                    className="formFive forms"
                    //  hidden={step < 5} style={step === 5 ? opacity : null}
                    style={
                      (step === 5 && enSouthAsia.includes(formData.region)) ||
                        (step === 4 && !enSouthAsia.includes(formData.region))
                        ? opacity
                        : null
                    }
                    hidden={
                      (enSouthAsia.includes(formData.region) && step < 5) ||
                      (!enSouthAsia.includes(formData.region) && step < 4)
                    }
                  >
                    <div id="formFiveName">
                      <span className="stepNumber">
                        {enSouthAsia.includes(formData.region) ? "06" : "05"}
                      </span>
                      <h2>{t("Register.fifthStep")}</h2>
                    </div>

                    <form
                      onSubmit={handleSubmit4(onSubmit4)}
                      autoComplete="off"
                    >
                      {/* <div className="inputContainer">
                        <Input
                          className="inputContainer"
                          id="GodFatherCheckbox"
                          name="Godfather"
                          type="checkbox"
                          onClick={(e) => setGodfatherBtn(!godfatherBtn)}
                          style={{ height: "auto", width: "10%" }}
                        />
                        <span>{t("Register.agency")}</span>
                      </div> */}
                      <FormGroup row>
                        {/* <div
                          className="inputContainer"
                          style={{ display: godfatherBtn ? "block" : "none" }}
                        >
                          <input
                            name="godfatherid"
                            type="text"
                            {...register4("godfatherid")}
                          />
                          <div className="label-champ">
                            {t("Register.referral")}
                          </div>
                          <div className="label">
                            {t("Register.referralTitle")}
                          </div>
                          <div className="error">
                            {errors4.godfatherid && t("Register.referralError")}
                          </div>
                        </div> */}

                        <div className="inputContainer">
                          <input
                            name="voucher"
                            type="text"
                            {...register4("voucher")}
                            onChange={(e) => _checkVoucher(e)}
                          />
                          <div className="label-champ">
                            {t("Register.invitationCodeTitle")}
                          </div>
                          <div className="label">
                            {t("Register.invitationCode")}
                          </div>
                          <div className="error">
                            {errors4.voucher && t("Register.invitationError")}
                          </div>
                        </div>
                        <div className="inputContainer">
                          <Input
                            type="textarea"
                            name="CGV"
                            rows={9}
                            style={{
                              width: "100%",
                              borderRadius: "1vh",
                              paddingLeft: "0.4em",
                            }}
                            value={
                              t("Register.textCGV") +
                              t("Register.textCGV2") +
                              t("Register.textCGV3") +
                              t("Register.textCGV4") +
                              t("Register.textCGV5") +
                              t("Register.textCGV6")
                            }
                            readOnly={true}
                          />
                        </div>

                        <div className="inputContainer">
                          <Input
                            name="cgu"
                            type="checkbox"
                            id="CguCheckbox"
                            defaultChecked={false}
                            onClick={(e) => setCguBtn(!cguBtn)}
                            style={{
                              height: "auto",
                              width: "10%",
                              zIndex: "11",
                            }}
                          // {...register4("cgu", {required: 'This is required'})}
                          />
                          <span style={{ color: !cguBtn ? "red" : "white" }}>
                            {t("Register.CGV")}
                          </span>
                        </div>
                      </FormGroup>
                    </form>
                  </div>
                </div>

                <div className={`centred ${cguBtn ? "fadeIn" : "fadeOut"}`}>
                  {cguBtn &&
                    (!isValidVoucher ||
                      (Africa.includes(formData.region) &&
                        isValidVoucher &&
                        (voucherObject.voucherUid === "MMAxELLE" ||
                          voucherObject.voucherUid === "MMAxCIV")) ||
                      (Europe.includes(formData.region) &&
                        isValidVoucher &&
                        voucherObject.voucherUid === "studymodel")) && (
                      <div className="sendButton" onClick={togglePopup}>
                        {isOpen && (
                          <Popups
                            content={
                              <>
                                {/* <StripeCheckout
                                            label="pay"
                                            className="sendButton"
                                            stripeKey='pk_test_MMk6QzjFuJS3HgZIYTVAl9TI'
                                            billingAddress
                                            shippingAddress
                                            email={formData.email}
                                            amount={35 * 100}
                                            name={"Talens classic"}
                                            token={handleToken}
                                            currency="EUR"
                                        >
                                            
                                        </StripeCheckout> */}
                                {!enSouthAsia.includes(formData.region) && (
                                  <Popup
                                    trigger={

                                      <button
                                        className="stripeBtn"
                                        // type="submit"
                                        onClick={toggleStripe}
                                      >
                                        {t("Register.PayBtnStripe")}
                                      </button>
                                    }
                                    modal
                                    nested
                                  >
                                    {(close) => (
                                      <div className="modal">
                                        <button
                                          className="close"
                                          onClick={close}
                                        >
                                          &times;
                                        </button>

                                        <div className="content">
                                          <Elements stripe={stripePromise}>
                                            <Stripes
                                              handleToken={handleToken}
                                              email={formData.email}
                                              name={
                                                formData.firstName +
                                                formData.lastName
                                              }
                                              planSub={subInfo.plan}
                                              plan={stripePlan}
                                            />
                                          </Elements>
                                        </div>
                                      </div>
                                    )}
                                    {/* <Elements stripe={stripePromise}>
                                                            <Stripes
                                                                handleToken={props.handleToken}
                                                                billingAddress={props.billingAddress}
                                                                email={props.email}
                                                            />
                                                        </Elements> */}
                                  </Popup>
                                )}
                                {!enSouthAsia.includes(formData.region) && (
                                  <Paypal
                                    handleTokenPaypal2={handleTokenPaypal2}
                                    plan={ppPlanId}
                                  />
                                )}
                                {enSouthAsia.includes(formData.region) && (
                                  <div>
                                    <button
                                      className="stripeBtn sendButton"
                                      onClick={submitForms}
                                    >
                                      Pay with razorpay
                                    </button>
                                  </div>
                                )}
                              </>
                            }
                            handleClose={togglePopup}
                          />
                        )}
                        {t("Register.PayBtn")}
                      </div>
                    )}
                  {cguBtn &&
                    isValidVoucher &&
                    !(
                      voucherObject.voucherUid === "MMAxELLE" ||
                      voucherObject.voucherUid === "MMAxCIV" ||
                      voucherObject.voucherUid === "studymodel"
                    ) && (
                      <button
                        className="sendButton"
                        // type="submit"
                        onClick={sendVoucher}
                      >
                        {t("Register.PayBtn")}
                      </button>
                    )}
                </div>
                <div
                  className={`footer ${cguBtn &&
                    ((enSouthAsia.includes(formData.region) &&
                      document.getElementById("numStep").innerText === "6") ||
                      (!enSouthAsia.includes(formData.region) &&
                        document.getElementById("numStep").innerText === "5"))
                    ? "fadeOut"
                    : "fadeIn"
                    }`}
                >
                  <div id="taskBar">
                    <div className="footerText">
                      {t("Register.step")} &nbsp;
                      <div id="numStep"> 1</div>
                      &nbsp;/ {enSouthAsia.includes(formData.region) ? 6 : 5}
                    </div>
                    <div className="answered">
                      <div
                        className="firstStep"
                        style={step >= 2 ? style1 : null}
                      ></div>
                      <div
                        className="test"
                        style={step === 2 ? style2 : step > 2 ? style3 : null}
                      ></div>
                      <div
                        style={step === 3 ? style2 : step > 3 ? style3 : null}
                      ></div>
                      <div
                        style={step === 4 ? style2 : step > 4 ? style3 : null}
                      ></div>
                      <div
                        style={step === 5 ? style2 : step > 5 ? style3 : null}
                      ></div>
                      {enSouthAsia.includes(formData.region) && (
                        <div style={step >= 6 ? style2 : null}></div>
                      )}
                    </div>
                  </div>

                  <div
                    id="arrow-suitch"
                    className="arrow"
                    onClick={arrowFunction}
                  >
                    <div id="arrow" className="ok">
                      {" "}
                      &#8595;
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Forms;
