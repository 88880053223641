import React, { useRef, useEffect } from "react";
//import axios from "axios";
//export default function Paypal and call the parameter passed from the parent component
export default function Paypal(props) {
  const paypal = useRef();
  useEffect(() => {
    window.paypal
      .Buttons({
        createSubscription: function (data, actions) {
          return actions.subscription.create({
            plan_id: props.plan,
            // Creates the subscription
          });
        },
        onApprove: async function (data, actions) {
          console.log("paypal", data);
          const subscription = await actions.subscription.get(
            data.subscriptionID
          );
          console.log(subscription);
          props.handleTokenPaypal2(data, { subscription });
        },
        //add onError function to handle errors
        onError: function (err) {
          alert(err);
        },
        //add style to the button
        style: {
          color: "white",
        },
      })

      .render(paypal.current); // Renders the PayPal button
  }, []);

  return (
    <div>
      <div ref={paypal}></div>
    </div>
  );
}
